import React from "react";
import styles from "./livestream.module.css";

function LiveStreamTableCell(props) {
  const defaultTitles = {
    id: "ID",
    created: "Created",
    classification: "Classification",
    order: "Order",
    frequency: "Frequency",
    device: "Device",
  };

  const dataPoint = props.dataPoint || defaultTitles;
  const isDefaultTitle = !props.dataPoint;

  // Define a function to return the appropriate class name
  const dataSectionClass = (isBold) =>
    `${styles.data_section} ${isBold ? styles.boldText : ""}`;

  return (
    <div className={styles.table_cell}>
      <div className={styles.checkbox_section}>
        <div className={styles.checkbox}></div>
      </div>
      <div className={dataSectionClass(isDefaultTitle)}>{dataPoint.id}</div>
      <div className={dataSectionClass(isDefaultTitle)}>
        {dataPoint.created}
      </div>
      <div className={dataSectionClass(isDefaultTitle)}>
        {dataPoint.classification}
      </div>
      <div className={dataSectionClass(isDefaultTitle)}>{dataPoint.order}</div>
      <div className={dataSectionClass(isDefaultTitle)}>
        {dataPoint.frequency}
      </div>
      <div className={dataSectionClass(isDefaultTitle)}>{dataPoint.device}</div>
    </div>
  );
}

export default LiveStreamTableCell;
