import React from "react";
import styles from "./livestream.module.css";

import LiveStreamTableContent from "./LiveStreamTableContent";

function LiveStreamComponent() {
  return (
    <div className={styles.main_layout}>
      <div className={styles.header_section}>
        <div className={styles.top_controls}>
          <p className={styles.page_title}>Live Stream</p>
          <div className={styles.top_control_buttons}>
            <div className={styles.top_buttons}>Print</div>
            <div className={styles.top_buttons}>Export</div>
          </div>
        </div>
        <div className={styles.header_divider}></div>
        <div className={styles.search_filter_controls}>
          <div className={styles.left_search_filter_controls}>
            <div className={styles.search_bar}>
              <img
                className={styles.search_icon}
                src="/images/GeneralIcons/SearchIcon.png"
                alt="search"
              ></img>
              <div className={styles.search_divider}></div>
              <input
                placeholder="Search"
                className={styles.search_input}
              ></input>
            </div>
            <div className={styles.search_filter}>
              <img
                className={styles.search_filter_icon}
                src="/TimeRangeIcon.png"
                alt="search"
              ></img>
              <p className={styles.filter_text}>Time Range</p>
              <img
                className={styles.down_icon}
                src="/DownArrowIcon.png"
                alt="search"
              ></img>
            </div>
            <div className={styles.search_filter}>
              <img
                className={styles.search_filter_icon}
                src="/SearchFilterIcon.png"
                alt="search"
              ></img>
              <p className={styles.filter_text}>Filters</p>
              <img
                className={styles.down_icon}
                src="/DownArrowIcon.png"
                alt="search"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <LiveStreamTableContent />
    </div>
  );
}

export default LiveStreamComponent;
