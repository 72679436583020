import styles from "./navbar.module.css";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import NotificationsSidebar from "../Notifications/Notifications";
import { AuthContext } from "../../context/AuthContext";

export default function Navbar(props) {
  const { logout, profile } = useContext(AuthContext);
  async function handleLogout() {
    try {
      await logout();
      navigate("/sign-in"); // Navigate after successful logout
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  const navigate = useNavigate();
  const navigateTo = (url) => {
    return navigate(url);
  };

  function switchToUserMode() {
    navigate("/dashboard");
  }

  function handleSettings() {
    navigate("/settings");
  }

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleNotificationsClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isToggled, setIsToggled] = useState(false);

  return (
    <div className={styles.portal_navbar}>
      <div className={styles.portal_navbar_sidebar_extension}></div>
      <div className={styles.portal_navbar_content}>
        <div className={styles.portal_navbar_left_content}></div>
        <div className={styles.portal_navbar_right_content}>
          {notificationsOpen && (
            <div className={styles.notifications_sidebar}>
              {/* <p className={classes.notifications_sidebar_text}>
                  Notifications
                </p> */}
              <NotificationsSidebar />
            </div>
          )}
          {/* <ProfileMenu /> */}
          {/* <div className={styles.navbar_item}></div> */}
          <div
            className={styles.navbar_item}
            onClick={handleNotificationsClick}
          >
            <img
              src="/images/NavbarIcons/NotificationsIcon.png"
              alt="icon"
              className={styles.navbar_icon}
            ></img>
          </div>
          {profile && (
            <div className={styles.account_navbar_item} onClick={toggleMenu}>
              <img
                src={profile.profilePicture || "/DefaultAccountImage.png"}
                alt="icon"
                className={styles.account_pfp}
              ></img>
              <div className={styles.account_info}>
                <p className={styles.account_name}>
                  {profile.firstName} {profile.lastName}
                </p>
                {/* <p className={styles.account_position}>CEO & Founder</p> */}
              </div>
            </div>
          )}
          {showMenu && (
            <div className={styles.menu}>
              <div className={styles.menu_item} onClick={handleSettings}>
                <p className={styles.menu_item_text}>Settings</p>
              </div>
              <div className={styles.menu_item}>
                <p className={styles.menu_item_text}>Contact Us</p>
              </div>
              <div className={styles.menu_item}>
                <p className={styles.menu_item_text}>Share</p>
              </div>
              <div className={styles.menu_item} onClick={handleLogout}>
                <p className={styles.menu_item_text}>Logout</p>
              </div>
              {/* <div className={styles.menu_item}>
                <p className={styles.menu_item_text}>ReplyAssist Tutorial</p>
              </div>
              <div className={styles.menu_item}>
                <p className={styles.menu_item_text}>Pause all automation</p>
              </div> */}
              {/* <div className={styles.menu_divider}></div>
              <div className={styles.menu_item}>
                <p className={styles.menu_item_text}>Settings</p>
              </div>
              <div className={styles.menu_item}>
                <p className={styles.menu_item_text}>Logout</p>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
