import React from "react";
import { useContext, useState } from "react";
import styles from "./universal.module.css";
import { AuthContext } from "../../../context/AuthContext";

function SearchBarHeader(props) {
  // State to determine whether the menu is open or not
  const [menuOpen, setMenuOpen] = useState(false);

  function handleFilterClick() {
    // Toggle the menu state when filter button is clicked
    setMenuOpen((prevState) => !prevState);
  }
  return (
    <div className={styles.search_layout}>
      <div className={styles.search_bar}>
        <img
          className={styles.search_icon}
          src="/images/GeneralIcons/SearchIcon.png"
          alt="search"
        ></img>
        <div className={styles.search_divider}></div>
        <input placeholder="Search" className={styles.search_input}></input>
      </div>
      <div className={styles.search_filter} onClick={handleFilterClick}>
        <img
          className={styles.search_filter_icon}
          src="/images/GeneralIcons/FilterIcon.png"
          alt="filter"
        ></img>
      </div>

      {menuOpen && (
        <div className={styles.filter_menu}>
          <div className={styles.filter_section}>
            <div className={styles.sort_option}>
              <p>Sort A-Z</p>
              <div> </div>
            </div>
            <div className={styles.sort_divider}></div>
            <div className={styles.sort_option}>
              <p>Sort Classifications</p>
              <div> </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBarHeader;
