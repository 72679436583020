import React from "react";
import { useRef, useState, useContext } from "react";
import styles from "./admin.module.css";
import testApiCall from "./TestApiCall";
import { AuthContext } from "../../context/AuthContext";

function TestEnvironmentComponent() {
  const { profile } = useContext(AuthContext);
  const [accountID, setAccountID] = useState("");
  const [imageToClassify, setImageToClassify] = useState(null);

  //JUSTIN'S ACCOUNT ID 33DGzuc2mCbyOLlS51Ye1YS7avy2
  //DARA'S ACCOUNT ID Y7I3Jmp7dCXoank4WXKeTCSoPDp1

  const fileInputRef = useRef(null);

  const handleApiCall = async () => {
    if (!imageToClassify) {
      alert("No image selected");
      return;
    }

    try {
      const responseData = await testApiCall(imageToClassify, accountID);
      console.log(responseData);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageToClassify(file);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAccountIDChange = (event) => {
    setAccountID(event.target.value);
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Testing</p>
      <div className={styles.proflie_picture_section}>
        <div className={styles.profile_picture_bg}>
          <img
            alt="logo"
            src={
              (imageToClassify && URL.createObjectURL(imageToClassify)) ||
              "/DefaultImage.png"
            }
            className={styles.profile_picture}
          ></img>
        </div>
        <div className={styles.update_profile_picture}>
          <p onClick={handleUploadButtonClick}>Upload Image</p>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleProfilePictureChange}
          />
        </div>
      </div>
      <div className={styles.accountID}>
        <span className={styles.accountIDBold}>Your Account ID: </span>

        {profile && profile.uid}
      </div>
      <div className={styles.profile_section}>
        <div className={styles.profile_picture_section_left}>
          <p className={styles.input_title}>Account ID</p>
          <input
            className={styles.single_line_input}
            type="text"
            value={accountID}
            onChange={handleAccountIDChange}
          ></input>
          <p className={styles.input_title}>Latitude (Optional)</p>
          <div className={styles.single_line_input}></div>
        </div>
        <div className={styles.profile_picture_section_right}>
          <p className={styles.input_title}>Taxon ID (Optional)</p>
          <div className={styles.single_line_input}></div>
          <p className={styles.input_title}>Latitude (Optional)</p>
          <div className={styles.single_line_input}></div>
        </div>
      </div>
      <div className={styles.button_section}>
        <div className={styles.button} onClick={handleApiCall}>
          Test API Call
        </div>
      </div>
    </div>
  );
}

export default TestEnvironmentComponent;
