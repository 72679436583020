import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import TestEnvironmentComponent from "../../components/admin_components/TestEnvironment";

const DeveloperPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<TestEnvironmentComponent />}
        activeText="Developer"
      />
    </>
  );
};

export default DeveloperPage;
