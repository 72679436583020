import React from "react";
import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from "./App";

// import NotFound from "./pages/NotFound";

import SignInPage from "./pages/Authentication/SignInPage";
// import SignUpPage from "./pages/Authentication/SignUpPage";

import HomePage from "./pages/Landing/HomePage";
import WaitlistPage from "./pages/Landing/WaitlistPage";

import DashboardPage from "./pages/Portal/DashboardPage";
import ClassificationsPage from "./pages/Portal/ClassificationsPage";
import LiveStreamPage from "./pages/Portal/LiveStreamPage";
import DeveloperPage from "./pages/Admin/DeveloperPage";
import SettingsPage from "./pages/Portal/SettingsPage";

import { AuthContext } from "./context/AuthContext";

function PrivateRoute({ children }) {
  const { currentUser } = useContext(AuthContext);

  // Check for user data in localStorage
  const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

  return isUserAuthenticated ? children : <Navigate to="/sign-in" />;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR LANDING PAGE */}
          <Route path="/" element={<App childComp={<HomePage />} />} />
          <Route
            path="/joinwaitlist"
            element={<App childComp={<WaitlistPage />} />}
          />
          <Route path="/home" element={<App childComp={<HomePage />} />} />
          {/* ROUTES FOR SIGNING IN AND SIGNING UP */}
          <Route path="/sign-in" element={<App childComp={<SignInPage />} />} />
          {/* <Route path="/signup" element={<App childComp={<SignUpPage />} />} /> */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/classifications"
            element={
              <PrivateRoute>
                <App childComp={<ClassificationsPage />} />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/live-stream"
            element={
              <PrivateRoute>
                <App childComp={<LiveStreamPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/developer"
            element={
              <PrivateRoute>
                <App childComp={<DeveloperPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
