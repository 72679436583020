import React from "react";
import styles from "./classifications.module.css";

function ClassificationsCard(props) {
  const classification = props.classification;

  return (
    <div className={styles.classifications_card}>
      <img
        className={styles.classifications_image}
        src={classification.publicUrl}
        alt="Classification"
      ></img>
      <div className={styles.classifications_info}>
        <div className={styles.classifications_subinfo}>
          <p className={styles.classification_title}>
            {classification.firstResult.taxon.preferred_common_name}
          </p>
          <p className={styles.classification_sub_title}>
            {classification.firstResult.taxon.name}
          </p>
          <p className={styles.classification_info_title}>
            Original Score: {classification.firstResult.original_combined_score}
          </p>
          {/* <p className={styles.classification_info_title}>
            Count: {classification.count}
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default ClassificationsCard;
