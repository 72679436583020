const notifications_data = [
  {
    id: "1",
    type: "opportunity_submission_accepted",
    image: "/v2Assets/profile_picture_assets/imageOne.png",
    text: "Congragulations! Your audio was selected.",
    time: "10min ago",
  },
  {
    id: "1",
    type: "connection_received",
    image: "/v2Assets/profile_picture_assets/imageTwo.png",
    text: "Justin Cose sent you a connection request",
    time: "45min ago",
  },
  {
    id: "1",
    type: "connection_accepted",
    image: "/v2Assets/profile_picture_assets/imageThree.png",
    text: "Justin Cose accepted your connection request",
    time: "59min ago",
  },
];

export default notifications_data;
