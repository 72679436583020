import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import HomePageComponent from "../../components/landing_components/home_page/home_component";

const HomePage = () => {
  return (
    <div>
      <LandingNavbar childComponent={<HomePageComponent />} activeText="Home" />
    </div>
  );
};

export default HomePage;
