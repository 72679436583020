// api.js

const testApiCall = async (imageFile, accountID) => {
  const formData = new FormData();
  formData.append("file", imageFile);

  if (accountID) {
    formData.append("accountID", accountID);
  }

  try {
    const response = await fetch(
      "https://nytelyfe-402203.uc.r.appspot.com/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // or handle response as needed
  } catch (error) {
    console.error("Error during API call:", error);
    throw error; // Rethrowing the error for handling it in the component
  }
};

export default testApiCall;
