import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";

const SignInComponent = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);

  //   const [feedback, setFeedback] = useState("");

  function handleSignUp() {
    navigate("/sign-up");
  }

  async function handleSubmit() {
    try {
      await signIn(email, password);
      navigate("/dashboard");
    } catch (error) {
      const errorCode = error.code;
      console.log("Code:", error.code);
      console.log("Message:", error.message);
      let errorMessage = "An error occurred during sign-in.";

      switch (errorCode) {
        case "auth/invalid-email":
          errorMessage =
            "Invalid email address. Please check your email and try again.";
          break;
        case "auth/wrong-password":
          errorMessage = "Incorrect password. Please try again.";
          break;
        case "auth/invalid-login-credentials":
          errorMessage = "Your username or password is incorrect.";
          break;
        // Add more error code cases and messages as needed
        default:
          errorMessage =
            "An error occurred during sign-in. Please try again later.";
      }

      console.error("Sign-in error:", error);
      setError(errorMessage);
    }
  }

  const canContinue = email !== "" && password !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          <img
            alt="logo"
            src="/NyteLyfeLogoGrayScale.png"
            className={styles.logo_icon}
          ></img>
          <p className={styles.page_title}>Login</p>
          <p className={styles.login_input_titles}>Email Address</p>
          <div className={styles.email_input}>
            <img
              className={styles.auth_input_icon}
              alt="email"
              src="/EmailIcon.png"
            ></img>
            <input
              onChange={(event) => setEmail(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          <p className={styles.login_input_titles}>Password</p>
          <div className={styles.email_input}>
            <img
              className={styles.auth_input_icon}
              alt="password"
              src="/PasswordIcon.png"
            ></img>
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          {error && <p className={styles.error_text}>{error}</p>}
          {canContinue ? (
            <div onClick={handleSubmit} className={styles.page_button}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          ) : (
            <div className={styles.page_button_inactive}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          )}
          {/* <p className={styles.note}>
            Don't have an account?{" "}
            <span className={styles.clickable_text} onClick={handleSignUp}>
              Sign Up
            </span>
          </p> */}
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/NyteLyfeHomeAbstractBackground.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default SignInComponent;
