import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import LiveStreamComponent from "../../components/portal_components/LiveStreamComponents/LiveStreamComponent";

const LiveStreamPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<LiveStreamComponent />}
        activeText="Live Stream"
      />
    </>
  );
};

export default LiveStreamPage;
