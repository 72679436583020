import React from "react";
import { useContext, useState } from "react";

import styles from "./home.module.css";
import LandingFooter from "../../../layout/LandingFooter/footer";
import { useNavigate } from "react-router";
// import { AuthContext } from "../../../context/AuthContext";

//ReplyAssist will
//-- do you lead generation
//-- respond to your linkedin direct messages
//-- complete your follow-up tasks
//-- schedule meetings for you
//-- monitor dealstage management

const value_props = [
  {
    image: "/images/ValuePropIcons/ValuePropOne.svg",
    title: "Detection & Tracking",
    subtitle:
      "Automated, real-time monitoring captures even the most elusive insects, enriching your data set without manual labor.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropTwo.svg",
    title: "Insect Classification",
    subtitle:
      "Leverage machine learning algorithms for precise species identification, turning raw images into actionable insights.",
  },
  {
    image: "/images/ValuePropIcons/ValuePropThree.svg",
    title: "User-Friendly Web Portal",
    subtitle:
      "Access and analyze your data effortlessly through an intuitive interface, designed with researchers in mind.",
  },
];

const HomePageComponent = () => {
  const [footerNewsletterEmail, setfooterNewsletterEmail] = useState("");
  // const { addNewslistUser } = useContext(AuthContext);
  const navigate = useNavigate();

  function handleJoinWaitlist() {
    navigate("/joinwaitlist");
  }

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.navbar_box}></div>
        <div className={styles.landing_header}>
          <div className={styles.main_copywriting}>
            <p className={styles.title_copywriting}>
              Explore Insects, Empower Research
            </p>
            <p className={styles.subtitle_copywriting}>
              A cutting-edge system designed for entomologists and
              environmentalists. Automate your insect photography,
              identification, and monitoring, turning complex data into
              actionable insights.
            </p>
          </div>

          <div className={styles.buttons}>
            <div className={styles.trial_button} onClick={handleJoinWaitlist}>
              <p className={styles.trial_button_text}>JOIN WAITLIST</p>
            </div>
          </div>
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/NyteLyfeHomeAbstractBackground.png"
        alt="landing"
      ></img>
      <div className={styles.banner}>
        <div className={styles.banner_content}>
          <p className={styles.banner_title}>How does it work?</p>
          <p className={styles.banner_content_text}>
            Harnessing the power of UV light, our device attracts a diverse
            array of insect species, ensuring robust data collection for your
            research. Coupled with a compact design, it easily integrates into
            various field conditions. Best of all, our plug-and-play setup
            eliminates complicated installations, letting you focus on what
            truly matters—your research.
          </p>
          {/* <div className={styles.banner_button}>
            <p>How it works</p>
          </div> */}
        </div>
      </div>
      <div className={styles.section}>
        <p className={styles.expierence_title}>
          NyteLife makes insect classifaction, easy.
        </p>
        <div className={styles.easier_cards}>
          {value_props.map((item) => (
            <div className={styles.easy_card}>
              <div className={styles.value_prop_circle}>
                <img
                  className={styles.value_prop_image}
                  src={item.image}
                  alt="icon"
                ></img>
              </div>
              <div className={styles.value_prop_text}>
                <p className={styles.value_prop_title}>{item.title}</p>
                <p className={styles.value_prop_subtitle}>{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.letter_section}>
        <div className={styles.letter_content}>
          <p className={styles.letter_text}>
            <strong>Recent Development</strong> <br /> <br />
            Our team has made strides to refine our object tracking algorithm,
            enhancing the reliability of our system by implementing confidence
            scores to each identified insect. We are currently working on the
            integration of the specialized computer visison algorithm for insect
            identification. While we are still in the developmental phases,
            these advancements are a promising step towards a more robust and
            accurate monitoring solution. The team is also laying the groundwork
            for a streamlined web portal by consulting insect researchers on
            what data is most critical to them. The ongoing efforts reflect our
            commitment to acquiring data related to biodiversity and population
            in remote areas.
          </p>
        </div>
      </div>
      <div className={styles.expierence_section}>
        <p className={styles.expierence_title}>Trusted by Insect Experts</p>
        <div className={styles.logos}>
          <img
            className={styles.expierence_logo}
            src="/images/TrustedByLogos/UWLogo.png"
            alt="landing"
          ></img>
          <img
            className={styles.expierence_logo}
            src="/images/TrustedByLogos/DNRLogo.png"
            alt="landing"
          ></img>
        </div>
      </div>

      <LandingFooter />
    </div>
  );
};

export default HomePageComponent;
