import React from "react";
import { useContext } from "react";
import styles from "./classifications.module.css";
import classifications_data from "../../../data/classifications_data";

import SearchBarHeader from "../UniversalComponents/search_bar_header";
import ClassificationsCard from "./ClassificationCard";
import { AuthContext } from "../../../context/AuthContext";

function ClassificationsComponent() {
  const { classifications } = useContext(AuthContext);

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Classifications</p>
      <SearchBarHeader />
      {classifications && (
        <div className={styles.table_scroll}>
          {classifications.map((classification, index) => {
            return (
              <ClassificationsCard
                key={index}
                classification={classification}
              />
            );
          })}
          <div className={styles.buffer}></div>
        </div>
      )}
    </div>
  );
}

export default ClassificationsComponent;
