import React from "react";
import { useState, useContext } from "react";

import styles from "./waitlist.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const JoinWaitlistPage = () => {
  const navigate = useNavigate();

  const { addWaitlistUser } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const [hideConfirmationPage, setHideConfirmationPage] = useState(true);
  //   const [feedback, setFeedback] = useState("");

  function handleSubmit() {
    addWaitlistUser(email, name, number, company);
    setHideConfirmationPage(false);
  }

  function navigateHome() {
    navigate("/");
  }

  const canContinue =
    name !== "" && email !== "" && number !== "" && company !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          {hideConfirmationPage ? (
            <>
              <p className={styles.join_waitlist}>Waitlist Form</p>
              <div className={styles.email_input}>
                <input
                  onChange={(event) => setName(event.target.value)}
                  placeholder="First and Last Name"
                  className={styles.input}
                ></input>
              </div>
              <div className={styles.email_input}>
                <input
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Email Address"
                  className={styles.input}
                ></input>
              </div>
              <div className={styles.email_input}>
                <input
                  onChange={(event) => setNumber(event.target.value)}
                  placeholder="Phone Number"
                  className={styles.input}
                ></input>
              </div>
              <div className={styles.email_input}>
                <input
                  onChange={(event) => setCompany(event.target.value)}
                  placeholder="Company Name"
                  className={styles.input}
                ></input>
              </div>
              {/* <div className={styles.email_input}>
            <input
              onChange={(event) => setLinkedin(event.target.value)}
              placeholder="Linkedin URL"
              className={styles.input}
            ></input>
          </div> */}

              {canContinue ? (
                <div onClick={handleSubmit} className={styles.join_button}>
                  <p className={styles.join_button_text}>Join Waitlist</p>
                </div>
              ) : (
                <div className={styles.join_button_inactive}>
                  <p className={styles.join_button_text}>Join Waitlist</p>
                </div>
              )}
              <p className={styles.note}>
                By submitting this form, we will be opting you into our free
                email newsletter.
              </p>
            </>
          ) : (
            <div className={styles.waitlist_confirmation_layout}>
              <div className={styles.checkmark_background}>
                <img
                  className={styles.checkmark}
                  src="/RedCheckmark.png"
                  alt="check"
                ></img>
              </div>
              <p className={styles.waitlist_confirmation_text}>
                You're on the waitlist!
              </p>
              <div className={styles.home_button} onClick={navigateHome}>
                Home
              </div>
            </div>
          )}
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/NyteLyfeHomeAbstractBackground.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default JoinWaitlistPage;
