import React from "react";
import { useState, useEffect, useContext } from "react";
// import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

import styles from "./footer.module.css";

const LandingFooter = () => {
  // const { addUserToNewsletter } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [newsletterSubcribed, setNewsletterSubcribed] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // function handleSubmit() {
  //   setNewsletterSubcribed(false);
  //   addUserToNewsletter(email);
  // }

  function handleJoinWaitlist() {
    navigate("/joinwaitlist");
  }

  function handleFAQ() {
    navigate("/FAQ");
  }

  const renderMobileFooter = () => (
    // Your mobile-specific footer JSX here
    <div className={styles.footer_mobile}>
      <p className={styles.footer_mobile_title}>NyteLyfe</p>
      <p className={styles.footer_mobile_email}>Email: Info@nytelyfe.org</p>
      <div className={styles.footer_mobile_links}>
        <p className={styles.footer_mobile_link}>Home</p>
        <p className={styles.footer_mobile_link} onClick={handleJoinWaitlist}>
          Join Waitlist
        </p>
        <p className={styles.footer_mobile_link}>FAQ</p>
      </div>
      <div className={styles.footer_mobile_newsletter_layout}>
        <p className={styles.footer_mobile_newsletter_title}>
          NyteLyfe Newsletter
        </p>
        <div className={styles.footer_mobile_newsletter_email_input}>
          <input
            className={styles.mobile_email_input}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Email Address"
          ></input>
          <div className={styles.mobile_email_bt} /*onClick={handleSubmit}*/>
            <p className={styles.mobile_email_bt_text}>Subscribe</p>
          </div>
        </div>
      </div>
      <p className={styles.footer_mobile_addy}>
        1015 Mound Street Suite 315 53715 Madison, Wisconsin
      </p>
      <p className={styles.footer_mobile_copyright}>
        © Copyright 2023 NyteLyfe. All Rights Reserved.
      </p>
    </div>
  );

  const renderDesktopFooter = () => (
    <div className={styles.footer}>
      <div className={styles.footer_left}>
        <p className={styles.footer_title}>NyteLyfe</p>
        <div className={styles.footer_addy_box}>
          <p className={styles.footer_addy}>NyteLyfe</p>
          <p className={styles.footer_addy}>1015 Mound Street</p>
          <p className={styles.footer_addy}>Suite 315</p>
          <p className={styles.footer_addy}>Madison, Wisconsin 53715</p>
          <p className={styles.email_text}>Email: Info@nytelife.org</p>
        </div>
      </div>
      <div className={styles.footer_right}>
        <div className={styles.footer_right_top}>
          <div className={styles.footer_link_layout}>
            <p className={styles.links_title}>Information</p>
            <p className={styles.links} onClick={handleJoinWaitlist}>
              Join Waitlist
            </p>
            {/* <p className={styles.links}>Pricing</p> */}
            {/* <p className={styles.links}>Sign Up</p> */}
            {/* <p className={styles.links}>Testimonials</p> */}
          </div>
          <div className={styles.footer_link_layout}>
            <p className={styles.links_title}>Resources</p>
            <p className={styles.links} onClick={handleFAQ}>
              FAQ
            </p>
            {/* <p className={styles.links}>Support</p> */}
            {/* <p className={styles.links}>Refer a Teammate</p> */}
          </div>
          <div className={styles.footer_link_layout}></div>
          <div className={styles.email_signup}>
            <p className={styles.weekly_title}>NyteLyfe Newsletter</p>
            <div className={styles.email_input_layout}>
              <input
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email Address"
                className={styles.email_input}
              />
              <div
                className={styles.subscribe_button} /*onClick={handleSubmit}*/
              >
                <p className={styles.subscribe_button_text}>Subscribe</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer_right_bottom}>
          <p className={styles.copyright_text}>
            © Copyright 2023 NyteLyfe. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );

  return isMobile ? renderMobileFooter() : renderDesktopFooter();
};

export default LandingFooter;
