const main_menu = [
  {
    id: 1,
    title: "Dashboard",
    icon_image: "/images/SidebarIcons/DashboardSidebarIcon.png",
    iconKey: "stroke",
    url: "/dashboard",
    isSelf: true,
  },
  {
    id: 2,
    title: "Classifications",
    icon_image: "/images/SidebarIcons/ClassificationsSidebarIcon.png",
    iconKey: "stroke",
    url: "/classifications",
    isSelf: true,
  },
  {
    id: 3,
    title: "Live Stream",
    icon_image: "/images/SidebarIcons/LiveStreamSidebarIcon.png",
    iconKey: "stroke",
    url: "/live-stream",
    isSelf: true,
  },
  {
    id: 3,
    title: "Developer",
    icon_image: "/images/SidebarIcons/DeveloperSidebarIcon.png",
    iconKey: "stroke",
    url: "/developer",
    isSelf: true,
  },
  {
    id: 4,
    title: "Settings",
    icon_image: "/images/SidebarIcons/SettingsSidebarIcon.png",
    iconKey: "stroke",
    url: "/settings",
    isSelf: true,
  },
];

export default main_menu;
